// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-drug-info-js": () => import("./../../../src/pages/drug-info.js" /* webpackChunkName: "component---src-pages-drug-info-js" */),
  "component---src-pages-drug-situation-in-georgia-js": () => import("./../../../src/pages/drug-situation-in-georgia.js" /* webpackChunkName: "component---src-pages-drug-situation-in-georgia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-scientific-publications-js": () => import("./../../../src/pages/scientific-publications.js" /* webpackChunkName: "component---src-pages-scientific-publications-js" */),
  "component---src-pages-technical-reports-js": () => import("./../../../src/pages/technical-reports.js" /* webpackChunkName: "component---src-pages-technical-reports-js" */),
  "component---src-pages-trainings-js": () => import("./../../../src/pages/trainings.js" /* webpackChunkName: "component---src-pages-trainings-js" */),
  "component---src-templates-drug-info-post-js": () => import("./../../../src/templates/drug-info-post.js" /* webpackChunkName: "component---src-templates-drug-info-post-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */)
}

